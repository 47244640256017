export const METAMASK_REDIRECT_URL = 'https://metamask.app.link/dapp/https://deeddroppers.com';
export const CONTRACT_ADDRESS = '0x3B10CAAc09761aAE4b4D2a832cf74486cdA65Fbf';
export const CONTRACT_ADDRESS_TESTNET = '0x3B10CAAc09761aAE4b4D2a832cf74486cdA65Fbf';
export const STATUS_READY = "👆🏽 Press the buttons to mint your NFT(s).";
export const STATUS_NOT_READY = "🦊 Connect to Metamask using the top left button.";
export const STATUS_NO_MASK = <p>
  {" "}
  🦊{" "}
  <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
    You must use Metamask or a Web3 browser to mint.
  </a>
</p>
