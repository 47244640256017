export const MintCounter = props => {
  return (
    <div className="ctr" id="mint-counter">
      <div style={{
        width: "200px",
        backgroundColor: "white",
        fontWeight: 600,
        color: "black",
        borderRadius: "45px",
        padding: "8px",
        border: "3px solid black",
        boxShadow: "0px 0px 20px rgba(215,0,8,1)"
        }}>
        {props.minted} / {props.maxTokens} minted
      </div>
    </div>
  )
}
