export const NFTInput = props => {
    let styles = props.styles

    const handleStartTokenChange = (e) => {
        props.setStartToken(e.target.value)
    }
    const handleEndTokenChange = (e) => {
        props.setEndToken(e.target.value)
    }
    const handleNftSelection = (e) => {
        props.setSelectedNft(e.target.value)
    }
    
    return (
        <div style={styles.centered}>
            <h2 style={styles.subtitle}>Eligible Token ID Range:</h2>
            <input
                style={styles.input}
                type="number"
                placeholder="Start Token ID"
                onChange={handleStartTokenChange}
                value={props.startToken ? props.startToken : ''}
            /><br></br>
            <input
                style={styles.input}
                type="number"
                placeholder="End Token ID"
                onChange={handleEndTokenChange}
                value={props.endToken ? props.endToken : ''}
            /><br></br>       
            <h2 style={styles.subtitle}>Select NFT to Drop:</h2>

            <select 
                style={styles.input}
                onChange={handleNftSelection}
                value = {props.selectedNft ? props.selectedNft : ''}
            >
                <option value="">Select NFT</option>
                {props.nfts.map((nft, i) => {
                    return (
                        <option value={i} key={i}>{nft.description}</option>
                    )
                })}
            </select>
        </div>
    )
}