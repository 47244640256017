import { METAMASK_REDIRECT_URL, CONTRACT_ADDRESS, CONTRACT_ADDRESS_TESTNET, STATUS_READY, STATUS_NOT_READY } from "./constants";
import { Network, Alchemy } from "alchemy-sdk";

require('dotenv').config();
const env = process.env.NODE_ENV;
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const alchemyApiKey = process.env.REACT_APP_ALCHEMY_API_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const settings = {
  apiKey: alchemyApiKey,
  network: env === "development" ? Network.ETH_GOERLI : Network.ETH_MAINNET,
};

const alchemy = new Alchemy(settings);

const contractABI = env === "development" ? require('../contract-abi-testnet.json') : require('../contract-abi.json');
const contractAddress = env === "development" ? CONTRACT_ADDRESS_TESTNET : CONTRACT_ADDRESS;

const etherscanPrefix = env === "development" ? "https://goerli.etherscan.io/tx/" : "https://etherscan.io/tx/";

export const getLogs = async (txHash) => {
  const receipt = await alchemy.core.getTransactionReceipt(txHash);
  const logs = receipt.logs;
  const tokenDroppedLog = logs[1];
  const tokenTransferLog = logs[0];

  const address = web3.eth.abi.decodeParameter("address", tokenTransferLog.topics[2]);

  const droppedToken = web3.eth.abi.decodeLog([
    {
      "name": "seed",
      "type": "uint256"
    },
    {
      "name": "randomNumber",
      "type": "uint256"
    },
    {
      "name": "winningTokenId",
      "type": "uint256"
    },
    {
      "name": "droppedTokenAddress",
      "type": "address"
    },
    {
      "name": "droppedTokenId",
      "type": "uint256"
    }
  ], tokenDroppedLog.data, [tokenDroppedLog.topics[1]]);

  return {
    seed: droppedToken.seed,
    randNum: droppedToken.randomNumber,
    winningTokenId: droppedToken.winningTokenId,
    droppedTokenId: droppedToken.droppedTokenId,
    droppedTokenAddress: droppedToken.droppedTokenAddress,
    winningAddress: address,
    txHash: txHash
  };
}

export const dropNft = async (startToken, endToken, address, tokenId) => {
  if (window.ethereum) {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);

    const transactionParameters = {
      to: contractAddress,
      from: window.ethereum.selectedAddress,
      data: window.contract.methods.drop(startToken, endToken, address, tokenId).encodeABI()
    };

    try {
      const txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      });
      return {
        success: true,
        status: 'Transaction submitted.',
        txHash: txHash,
      };
    }
    catch (error) {
      return {
        success: false,
        status: 'Something went wrong.',
      };
    }
  }
}

export const getPriceOne = async () => {
  const contract = await new web3.eth.Contract(contractABI, contractAddress);
  const price = await contract.methods.priceOne().call();
  return price / (10 ** 18);
}

export const getPriceTwo = async () => {
  const contract = await new web3.eth.Contract(contractABI, contractAddress);
  const price = await contract.methods.priceTwo().call();
  return price / (10 ** 18);
}

export const getPriceThreeOrMore = async () => {
  const contract = await new web3.eth.Contract(contractABI, contractAddress);
  const price = await contract.methods.priceThreeOrMore().call();
  return price / (10 ** 18);
}

export const getMinted = async () => {
  const contract = await new web3.eth.Contract(contractABI, contractAddress);
  const minted = await contract.methods.totalSupply().call();
  return minted;
}

export const isDelegate = async (address) => {
  if (!address) {
    return false;
  }
  const contract = await new web3.eth.Contract(contractABI, contractAddress);
  const delegated = await contract.methods.isDelegate(address).call();
  return delegated;
}

export const getMaxTokens = async () => {
  const contract = await new web3.eth.Contract(contractABI, contractAddress);
  const maxTokens = await contract.methods.maxTokens().call();
  return maxTokens;
}

export const mintNFT = async (amount) => {
  if (window.ethereum) {
     window.contract = await new web3.eth.Contract(contractABI, contractAddress);

      //set up your Ethereum transaction
     var price;
     if (amount === 1) {
       price = await getPriceOne();
     } else if (amount === 2) {
       price = await getPriceTwo();
     } else {
       price = await getPriceThreeOrMore();
     }

     const transactionParameters = {
            to: contractAddress, // Required except during contract publications.
            from: window.ethereum.selectedAddress, // must match user's active address.
            value: web3.utils.toHex(web3.utils.toWei(String((price * amount).toFixed(4)), 'ether')), // set payment amount
            'data': window.contract.methods.mint(amount).encodeABI()//make call to NFT smart contract
     };

    //sign the transaction via Metamask
     try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: (
              <span>
                <p>
                  {" "}
                  ✅{" "}
                  Check out your transaction on Etherscan:{" "}
                  <a target="_blank" rel="noreferrer" id="success" href={`${etherscanPrefix}${txHash}`}>
                    {`${etherscanPrefix}${txHash}`}
                  </a>
                </p>
              </span>
            ),
        }
     } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }

     }

   } else {
     window.location.href = METAMASK_REDIRECT_URL;
     return {
       address: "",
       status: (
         <span>
           <p>
             {" "}
             🦊{" "}
             <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
               You must use Metamask or a Web3 browser to mint.
             </a>
           </p>
         </span>
       ),
     };
   }

}

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: STATUS_READY,
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    window.location.href = METAMASK_REDIRECT_URL;
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must use Metamask or a Web3 browser to mint.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: STATUS_READY,
          chainId: window.ethereum.chainId,
        };
      } else {
        return {
          address: "",
          status: STATUS_NOT_READY,
          chainId: -1,
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
        chainId: -1,
      };
    }
  } else {

    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must use Metamask or a Web3 browser to mint.
            </a>
          </p>
        </span>
      ),
    };
  }
};
