import React from 'react';
import {nfts} from '../utils/metadata';
import toast from 'react-hot-toast';

const RenderMainPrize = props => {
  return (
    <div id="main-prize-container">
      <span>{renderNft(0)}</span>
      <p className = "prize-text" style={{fontSize: "30px"}}>
        {nfts[0].description}
        <span className="break"></span>
        {nfts[0].twitterLink}&nbsp;
        {nfts[0].osLink}
      </p>
    </div>
  )
}

const RenderSecondaryPrizes = props => {
  const cards = nfts.map((nft, i) => !i ? '' :
      nft.name.slice(0, 4) === "test" ? '' :
      <div className="nft-single" key={i}>
        <span>{renderNft(i)}</span>
        <div className="prize-text-container">
          <div className = "prize-text">
            {nfts[i].jsx ? nfts[i].jsx : nfts[i].description}
            <div className="break"></div>
            {nfts[i].twitterLink}&nbsp;
            {nfts[i].osLink}
          </div>
        </div>
      </div>
  )

  return (
    <div id="nft-container">
      {cards}
    </div>
  )
}

const renderNft = (i) => {
  var src = nfts[i].img;
  var nft = nfts[i].name;
  const element = <img src={src} className={i === 0 ? "main-prize" : "nft"} id={nft}
    onClick={() => nftToast(i) } alt="NFT to be dropped"></img>;
  return element;
}

const nftToast = (i) => {
  let msg;
  let icon;
  if (i === 0) {
    msg = "I'm the big prize!";
    icon = "🏆";
  } else {
    msg = "I'm gonna get dropped!";
    icon = "🪂";
  }
  toast(msg, {icon: icon});
}

export const RenderPrizes = props => {
  return (
    <div id="prizes">
      <div style={styles.v1Drops}>v1 Drops</div>
      <RenderMainPrize/>
      <RenderSecondaryPrizes/>
    </div>
  );
}

const styles = {
  v1Drops: {
    fontSize: "52px",
    height: "78px",
    backgroundColor: "black",
    opactity: 0.8,
    width: "250px",
    borderRadius: "16px",
    border: "1px solid black",
    padding: "4px",
    backgroundImage: "linear-gradient(to bottom, rgba(221, 0, 8, 1) 0%, rgba(0, 0, 0, 1) 100%), linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(108, 108, 108, 1) 100%)",
    backgroundClip: "content-box, padding-box"
  },
}
