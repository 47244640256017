import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"

import {
  getCurrentWalletConnected,
  getPriceOne,
  getPriceTwo,
  getPriceThreeOrMore,
  getMinted,
  getMaxTokens
} from "../utils/interact.js";

import { STATUS_READY, STATUS_NOT_READY, STATUS_NO_MASK } from '../utils/constants';

import { Toaster } from 'react-hot-toast';

import { MintCounter, PriceTag, Spacer, 
          QtySelector, RenderTextContent, RenderPrizes,
          RenderLogo, ConnectButton, MintButton, Status } from '../components';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab, fas);

const Minter = (props) => {

  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [price, setPrice] = useState(-1);
  const [priceOne, setPriceOne] = useState(-1);
  const [priceTwo, setPriceTwo] = useState(-1);
  const [priceThreeOrMore, setPriceThreeOrMore] = useState(-1);
  const [minted, setMinted] = useState(-1);
  const [maxTokens, setMaxTokens] = useState(-1);
  const [amount, setAmount] = useState(1);
  const [mintActive, ] = useState(false);
  const [chainId, setChainId] = useState(-1);

  const env = process.env.NODE_ENV;

  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus(STATUS_READY);
        } else {
          setWallet("");
          setStatus(STATUS_NOT_READY);
        }
      });
      window.ethereum.on("chainChanged", (_chainId) => {
        setChainId(_chainId)
      });
    } else {
      setStatus(STATUS_NO_MASK);
    }
  }

  useEffect(() => {
    const getData = async () => {
      const {address, status, chainId } = await getCurrentWalletConnected();
      setWallet(address);
      setStatus(status);
      setChainId(chainId);
      setPrice(await getPriceOne());
      setPriceOne(await getPriceOne());
      setPriceTwo(await getPriceTwo());
      setPriceThreeOrMore(await getPriceThreeOrMore());
      setMinted(env === 'development' ? await getMinted() : -1);
      setMaxTokens(await getMaxTokens());
      addWalletListener();
    }
    getData();
  }, [env]);

  return (
    <div className="Minter">
      <Toaster
        toastOptions={{
          position: 'top-center',
          style: {
            background: '#1A1A1A',
            color: '#fffcef',
            textAlign: 'center',
          },
        }}/>

      <ConnectButton
        walletAddress={walletAddress}
        setStatus={setStatus}
        setWallet={setWallet}/>
      <div style={{margin: "0 auto", textAlign: "center"}}>
        <Spacer size="5"/>
        <RenderLogo/>
        <br></br>
        <Link to="/dropped">
          <button id="mint-now">🌀 Drop Winners! 🌀</button>
        </Link>
        <a href="#mint-counter">
          <button id="mint-now">👇 Jump to Mint 👇</button>
        </a>
        <RenderPrizes/>
        <Spacer size="2"/>
        <h1 id="mint-section">Mint Droppers</h1>
        <MintCounter
          minted={minted}
          maxTokens={maxTokens} />
        <Spacer size="2"/>
        <QtySelector
          amount={amount}
          priceOne={priceOne}
          priceTwo={priceTwo}
          priceThreeOrMore={priceThreeOrMore}
          setPrice={setPrice}
          setAmount={setAmount}/>
        <Spacer size="2"/>
        <PriceTag
          price={price}
          amount={amount} />
        <Spacer size="2"/>
        <MintButton
          mintActive={mintActive}
          amount={amount}
          setStatus={setStatus}/>
        <RenderTextContent/>
        <Spacer size="10"/>
      </div>

      <div className="footer">
        <Status status={status}/>
      </div>

    </div>
  );
};

export default Minter;
