import { Link } from "react-router-dom"
import { WinnerList, SelectionProcess } from '../components'

const Dropped = props => {
    return (
        <div className="Dropped">
            <h1 style={styles.title}>Dropped</h1>
            
            <WinnerList
                styles={styles}
            />
            <div style={styles.centered}>
                <Link to="/">
            <button style={styles.button}>Back to Minter</button>
                </Link>
            <SelectionProcess />
            </div>
        </div>

    )
}

const styles = {
    centered: {
        margin: "0 auto",
        textAlign: "center",
    },
    title: {
        fontSize: "3rem",
        fontWeight: "bold",
        marginBottom: "1rem",
        textAlign: "center",
        fontFamily: "Slackey"
    },
    button: {
        fontSize: "1.5rem",
        borderRadius: "0.5rem",
        border: "none",
        backgroundColor: "#aaa",
        color: "#1A1A1A",
        cursor: "pointer",
        margin: "1rem",
    },
}

export default Dropped;