import { connectWallet } from "../utils/interact.js";
import { toast } from 'react-hot-toast';

export const ConnectButton = props => {

  const connectWalletPressed = async () => {
     const walletResponse = await connectWallet();
     props.setStatus(walletResponse.status);
     props.setWallet(walletResponse.address);
  };

  return (
    <div id="connect-button">
      <button
        id={props.walletAddress.length > 0 ? "walletButtonConnected" : "walletButton"}
        onClick={props.walletAddress.length > 0 ? () => toast("Already connected!", {icon: "⚡"}) : connectWalletPressed}>
          {props.walletAddress.length > 0 ? (
            String(props.walletAddress).substring(0, 6) +
            "..." +
            String(props.walletAddress).substring(38)
          ) : (
            <span>Connect Wallet</span>
          )}
      </button>
    </div>
  )
}
