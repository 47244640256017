export const Spacer = props => {

  return (
    <div className="spacer"
      style={{
        padding: props.size + 'vh'
      }}
    >
    </div>
  )
}
