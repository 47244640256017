import { Eth, Caret } from '../utils/fas';
import Grid from '@mui/material/Grid';

export const QtySelector = props => {
  const onQtyPressed = async (qty) => {
    if (qty === 1) {
      props.setPrice(props.priceOne);
    } else if (qty === 2) {
      props.setPrice(props.priceTwo);
    } else {
      props.setPrice(props.priceThreeOrMore);
    }
    props.setAmount(qty);
  }
  return (
    <div id="selector-container">
    <Grid container spacing={2}>
      <Grid item xs={4}>
          <div className="price">{props.priceOne}{" "}<Eth/></div>
      </Grid>
      <Grid item xs={4}>
          <div className="price">{props.priceTwo}{" "}<Eth/></div>
      </Grid>
      <Grid item xs={4}>
          <div className="price">{props.priceThreeOrMore}{" "}<Eth/></div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <button
            style={{
              backgroundColor: props.amount === 1 ? 'white' : '',
              color: props.amount === 1 ? 'black' : '',
            }}
            className="qty" id="one" onClick={() => onQtyPressed(1)}
          >
            1
          </button>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <button
            style={{
              backgroundColor: props.amount === 2 ? 'white' : '',
              color: props.amount === 2 ? 'black' : '',
            }}
            className="qty" id="two" onClick={() => onQtyPressed(2)}
          >
            2
          </button>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <div className="dropdown">
            <button
              style={{
                backgroundColor: props.amount > 2 ? 'white' : '',
                color: props.amount > 2 ? 'black' : '',
              }}
              className="dropbtn" id="three" onClick={() => onQtyPressed(3)}
            >
              {props.amount > 3 ? props.amount : 3}{" "}<Caret/>
            </button>

            <div className="dropdown-content">
              <span
                onClick={props.amount >= 4 ? () => onQtyPressed(3) : () => onQtyPressed(4)}>{props.amount >= 4 ? 3 : 4}</span>
              <span
                onClick={props.amount >= 5 ? () => onQtyPressed(4) : () => onQtyPressed(5)}>{props.amount >= 5 ? 4 : 5}</span>
              <span
                onClick={props.amount >= 6 ? () => onQtyPressed(5) : () => onQtyPressed(6)}>{props.amount >= 6 ? 5 : 6}</span>
              <span
                onClick={props.amount >= 7 ? () => onQtyPressed(6) : () => onQtyPressed(7)}>{props.amount >= 7 ? 6 : 7}</span>
              <span
                onClick={props.amount >= 8 ? () => onQtyPressed(7) : () => onQtyPressed(8)}>{props.amount >= 8 ? 7 : 8}</span>
              <span
                onClick={props.amount >= 9 ? () => onQtyPressed(8) : () => onQtyPressed(9)}>{props.amount >= 9 ? 8 : 9}</span>
              <span
                onClick={props.amount >= 10 ? () => onQtyPressed(9) : () => onQtyPressed(10)}>{props.amount >= 10 ? 9 : 10}</span>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
    </div>
  )
}
