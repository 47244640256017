import { Astronaut, Deed1, Cub, Caveman, Goatgaud,
  Superpass, Superpass2, Cham, Homie, Puzlpack, Madrabbit,
  Ens, Deadfrenz, Gutterclone, Evobot, Toymorie,
  Swetiyeti901, Swetiyeti907, Swetiyeti910, Swetiyeti913,
  Cryptomorie, Nonfungiblefungi, Untamedelephant, NFTerrarium, Stonercat,
  Evilteddy, thwonder, Studiodao, Lazycomic, Testnft } from '../images/nfts/v1/';
import { Twitter } from './fas';
import Opensea from '../images/os.png';

export const nfts = [
  {
    name: "deed1",
    img: Deed1,
    description: "Otherdeed #54878",
    address: "0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258",
    tokenId: "54878",
    twitterLink: <a href="https://twitter.com/othersidemeta" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href={"https://opensea.io/assets/ethereum/0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258/54878"} target="_blank" rel="noreferrer">
      <span className="os-text-container" style={{height: "27px", width: "27px"}}>
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "gutterclone",
    img: Gutterclone,
    description: "Gutter Clone #5317",
    address: "0x41f20599e9e049004c4d169046eb7023117a6244",
    tokenId: "5317",
    twitterLink: <a href="https://twitter.com/GutterCatGang" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x41f20599e9e049004c4d169046eb7023117a6244/5317" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "cryptomorie",
    img: Cryptomorie,
    description: "Cryptomories #2082",
    address: "0x1a2f71468f656e97c2f86541e57189f59951efe7",
    tokenId: "2082",
    twitterLink: <a href="https://twitter.com/CryptoMories" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x1a2f71468f656e97c2f86541e57189f59951efe7/2082" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "stonercat",
    img: Stonercat,
    description: "Stoner Cats #3775",
    address: "0xd4d871419714b778ebec2e22c7c53572b573706e",
    tokenId: "3775",
    twitterLink: <a href="https://twitter.com/stonercatstv" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0xd4d871419714b778ebec2e22c7c53572b573706e/3775" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "nonfungiblefungi",
    img: Nonfungiblefungi,
    description: "Fungi #9832",
    address: "0x90ee3cf59fcde2fe11838b9075ea4681462362f1",
    tokenId: "9832",
    twitterLink: <a href="https://twitter.com/FungibleFungi" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x90ee3cf59fcde2fe11838b9075ea4681462362f1/9832" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "deadfrenz",
    img: Deadfrenz,
    description: "Deadfrenz #8215",
    address: "0x920a8d9e9f0defd6f86e4388a5503b04cac83b57",
    tokenId: "8215",
    twitterLink: <a href="https://twitter.com/Deadfellaz" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x920a8d9e9f0defd6f86e4388a5503b04cac83b57/8215" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "cub",
    img: Cub,
    description: "Lazy Cubs #2113",
    address: "0xe6a9826e3b6638d01de95b55690bd4ee7eff9441",
    tokenId: "2113",
    twitterLink: <a href="https://twitter.com/LazyLionsNFT" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0xe6a9826e3b6638d01de95b55690bd4ee7eff9441/2113" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "studiodao",
    img: Studiodao,
    description: "Studio TBD Membership Token",
    address: "0x9fbb230b1edd6c69bd0d8e610469031ab658f4b2",
    tokenId: "0",
    twitterLink: <a href="https://twitter.com/StudioTBD_NYC" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x9fbb230b1edd6c69bd0d8e610469031ab658f4b2/0" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "untamedelephant",
    img: Untamedelephant,
    description: "Untamed Elephant #4605",
    address: "0x613e5136a22206837d12ef7a85f7de2825de1334",
    tokenId: "4605",
    twitterLink: <a href="https://twitter.com/ElephantNFTS" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x613e5136a22206837d12ef7a85f7de2825de1334/4605" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "8thwonder",
    img: thwonder,
    description: "8th Wonder #900",
    address: "0x43e9103650ea0462afecf35b6b46b745a4eb4fab",
    tokenId: "900",
    twitterLink: <a href="https://twitter.com/8thwondersnft" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x43e9103650ea0462afecf35b6b46b745a4eb4fab/900" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "toymorie",
    img: Toymorie,
    description: "ToyMories #6059",
    address: "0xc3c62e97c85ea5d8d2edc39034e9dfc6452a50d1",
    tokenId: "6059",
    twitterLink: <a href="https://twitter.com/ToyMories" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0xc3c62e97c85ea5d8d2edc39034e9dfc6452a50d1/6059" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "evobot",
    img: Evobot,
    description: "EVO Bots #16",
    address: "0x69226261e908d24b0127ad890c467080fedd7090",
    tokenId: "16",
    twitterLink: <a href="https://twitter.com/Crypto_CaveClub" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x69226261e908d24b0127ad890c467080fedd7090/16" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "lazycomic",
    img: Lazycomic,
    description: "Lazy Comic #1",
    address: "0x8b0e42f366ba502d787bb134478adfae966c8798",
    tokenId: "1",
    twitterLink: <a href="https://twitter.com/LazyLionsNFT" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0xf1739d53e816212a788511e3b42f8604a9417a2a/0" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "swetiyeti913",
    img: Swetiyeti913,
    description: "Sweti Yeti #913",
    address: "0x752fdacb71e76a68ec949fe25ccfccd10eee2f9f",
    tokenId: "913",
    twitterLink: <a href="https://twitter.com/SYLCNFT" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x752fdacb71e76a68ec949fe25ccfccd10eee2f9f/913" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "swetiyeti910",
    img: Swetiyeti910,
    description: "Sweti Yeti #910",
    address: "0x752fdacb71e76a68ec949fe25ccfccd10eee2f9f",
    tokenId: "910",
    twitterLink: <a href="https://twitter.com/SYLCNFT" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x752fdacb71e76a68ec949fe25ccfccd10eee2f9f/910" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "swetiyeti907",
    img: Swetiyeti907,
    description: "Sweti Yeti #907",
    address: "0x752fdacb71e76a68ec949fe25ccfccd10eee2f9f",
    tokenId: "907",
    twitterLink: <a href="https://twitter.com/SYLCNFT" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x752fdacb71e76a68ec949fe25ccfccd10eee2f9f/907" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "swetiyeti901",
    img: Swetiyeti901,
    description: "Sweti Yeti #901",
    address: "0x752fdacb71e76a68ec949fe25ccfccd10eee2f9f",
    tokenId: "901",
    twitterLink: <a href="https://twitter.com/SYLCNFT" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x752fdacb71e76a68ec949fe25ccfccd10eee2f9f/901" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "nfterrarium",
    img: NFTerrarium,
    description: "NFTerrarium #150",
    address: "0x1876b1c045f3eab42c438984de72141230445735",
    tokenId: "150",
    twitterLink: <a href="https://twitter.com/nfterrarium" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x1876b1c045f3eab42c438984de72141230445735/150" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "caveman",
    img: Caveman,
    description: "Crypto Cave Club",
    address: "0x5cebe5cde01ab154fb46b7984d6354da367bcbf2",
    tokenId: "0",
    twitterLink: <a href="https://twitter.com/Crypto_CaveClub" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/collection/cryptocaveclub" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "goat",
    img: Goatgaud,
    description: "Goat Gauds #294",
    address: "0x5cebe5cde01ab154fb46b7984d6354da367bcbaf",
    tokenId: "294",
    twitterLink: <a href="https://twitter.com/GoatGauds" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x5cebe5cde01ab154fb46b7984d6354da367bcbaf/294" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "astronaut",
    img: Astronaut,
    description: "Kozmonauts #473",
    address: "0x3f20958d3f58aa4f3c14c9350976721a0acac440",
    tokenId: "473",
    twitterLink: <a href="https://twitter.com/KOZMONAUTS1" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x3f20958d3f58aa4f3c14c9350976721a0acac440/473" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "superpass2",
    img: Superpass2,
    description: "NFT Scribe Superpass #111",
    jsx: <span>NFT Scribe <br></br>Superpass #111</span>,
    address: "0x488ecf3c4675d15938b7bbf2e43d1c2fea73e7ce",
    tokenId: "111",
    twitterLink: <a href="https://twitter.com/NftScribeIO" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x488ecf3c4675d15938b7bbf2e43d1c2fea73e7ce/111" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "superpass",
    img: Superpass,
    description: 'NFT Scribe Superpass #112 [Oasir Artifact]',
    jsx: <span>NFT Scribe <br></br>Superpass #112<br></br><span style={{color:"yellow"}}>Oasir Artifact</span></span>,
    address: "0x488ecf3c4675d15938b7bbf2e43d1c2fea73e7ce",
    tokenId: "112",
    twitterLink: <a href="https://twitter.com/NftScribeIO" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x488ecf3c4675d15938b7bbf2e43d1c2fea73e7ce/112" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "puzlpack",
    img: Puzlpack,
    description: "PuzlPunk Pack #535",
    address: "0x1f0ad1f5280adf7ad971c0f911cc1f7a882033c5",
    tokenId: "535",
    twitterLink: <a href="https://twitter.com/puzlworld" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x1f0ad1f5280adf7ad971c0f911cc1f7a882033c5/535" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "madrabbit",
    img: Madrabbit,
    description: "Mad Rabbit #2958",
    address: "0x57fbb364041d860995ed610579d70727ac51e470",
    tokenId: "2958",
    twitterLink: <a href="https://twitter.com/MadRabbitsRC" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x57fbb364041d860995ed610579d70727ac51e470/2958" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "homie",
    img: Homie,
    description: "My Homies in Dreamland #9338",
    jsx: <span>My Homies in <br></br> Dreamland #9338</span>,
    address: "0xf22da0424d1dfd70099d30941bbaf35e7f986f1d",
    tokenId: "9338",
    twitterLink: <a href="https://twitter.com/MyHomies" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0xf22da0424d1dfd70099d30941bbaf35e7f986f1d/9338" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "cham",
    img: Cham,
    description: "Chameleon Collective #6652",
    jsx: <span>Chameleon <br></br> Collective #6652</span>,
    address: "0xfd3c3717164831916e6d2d7cdde9904dd793ec84",
    tokenId: "6652",
    twitterLink: <a href="https://twitter.com/Chameleon_NFT" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0xfd3c3717164831916e6d2d7cdde9904dd793ec84/6652" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "evilteddy",
    img: Evilteddy,
    description: "Evil Teddy Bear Club #1252",
    jsx: <span>Evil Teddy Bear Club <br></br>#1252</span>,
    address: "0x7b3d36eb606f873a75a6ab68f8c999848b04f935",
    tokenId: "1252",
    twitterLink: <a href="https://twitter.com/EvilTeddysNFT" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x4840f2182109539acbfef535bb6dea2d560b9d70/1252" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "ens",
    img: Ens,
    description: "ilookrare.eth",
    address: "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85",
    tokenId: "85676776184562990710350919029209857693985557820525644367331174756356796516482",
    twitterLink: <a href="https://twitter.com/ensdomains" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://opensea.io/assets/ethereum/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85/85676776184562990710350919029209857693985557820525644367331174756356796516482" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "test nft",
    img: Testnft,
    description: "Test NFT #973",
    address: "0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043",
    tokenId: "973",
    twitterLink: <a href="https://twitter.com/0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://testnets.opensea.io/assets/goerli/0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043/973" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "test nft 2",
    img: Testnft,
    description: "Test NFT #974",
    address: "0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043",
    tokenId: "974",
    twitterLink: <a href="https://twitter.com/0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://testnets.opensea.io/assets/goerli/0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043/974" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "test nft 3",
    img: Testnft,
    description: "Test NFT #975",
    address: "0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043",
    tokenId: "975",
    twitterLink: <a href="https://twitter.com/0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://testnets.opensea.io/assets/goerli/0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043/975" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "test nft 4",
    img: Testnft,
    description: "Test NFT #976",
    address: "0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043",
    tokenId: "976",
    twitterLink: <a href="https://twitter.com/0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://testnets.opensea.io/assets/goerli/0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043/976" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  },
  {
    name: "test nft 5",
    img: Testnft,
    description: "Test NFT #977",
    address: "0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043",
    tokenId: "977",
    twitterLink: <a href="https://twitter.com/0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043" target="_blank" rel="noreferrer"><Twitter/></a>,
    osLink: <a href="https://testnets.opensea.io/assets/goerli/0x932Ca55B9Ef0b3094E8Fa82435b3b4c50d713043/977" target="_blank" rel="noreferrer">
      <span className="os-text-container">
        <img src={Opensea} alt="Opensea Logo" id="os-icon"></img>
      </span>
    </a>
  }
]
