import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './App.css'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import reportWebVitals from './reportWebVitals'

import Minter from './pages/Minter'
import Dropper from './pages/Dropper'
import Dropped from './pages/Dropped'

import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom"


ReactDOM.render(
  <React.StrictMode>
    <Router>
        <Routes>
          <Route path='/' element={<Minter />} />
          <Route path='/dropper' element={<Dropper />} />
          <Route path='/dropped' element={<Dropped />} />
          <Route path='*' element={<Navigate to='' />} />
        </Routes>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()