import dotenv from 'dotenv'
import AWS from 'aws-sdk'

dotenv.config()

AWS.config.update({ region: 'us-east-1' })

const env = process.env.NODE_ENV;

const table = env === "development" ? 'deeddroppers_winners_testnet' : 'deeddroppers_winners';

const credentials = new AWS.Credentials({
    accessKeyId: process.env.REACT_APP_DYNAMODB_KEY,
    secretAccessKey: process.env.REACT_APP_DYNAMODB_SECRET,
    region: 'us-east-1',
})

const ddb = new AWS.DynamoDB({
    apiVersion: '2012-08-10',
    credentials: credentials,
})

export const addNewDBItem = (logs) => {

    const { seed, randNum, winningTokenId, droppedTokenAddress, droppedTokenId, winningAddress, txHash } = logs

    const timestamp = new Date().toISOString()
    const params = {
        TableName: table,
        Item: {
            'seed': { S: seed },
            'randNum': { S: randNum },
            'droppedTokenAddress': { S: droppedTokenAddress },
            'winningTokenId': { S: winningTokenId },
            'droppedTokenId': { S: droppedTokenId },
            'winningAddress': { S: winningAddress },
            'txHash': { S: txHash },
            'timestamp': { S: timestamp },
        }
    };

    // Call DynamoDB to add the item to the table
    ddb.putItem(params, function (err, data) {
        if (err) {
            console.log("Error", err)
        } else {
            console.log("Success", data)
        }
    })

    return {
        success: true,
        data: {
            seed: seed,
            randNum: randNum,
            winningTokenId: winningTokenId,
            droppedTokenAddress: droppedTokenAddress,
            droppedTokenId: droppedTokenId,
            winningAddress: winningAddress,
            txHash: txHash,
            timestamp: timestamp,
        }
    }
}

export const getWinners = async () => {
    let winners = [];

    const params = {
        TableName: table
    };
    
    try {
        const data = await ddb.scan(params).promise();
        winners = data.Items;
    } catch (err) {
        console.log(err);
    }

    return {
        success: true,
        data: winners
    }
}

export const unmarshal = (winners) => {
    return winners.map((winner) => {
        return {
            seed: winner.seed.S,
            randNum: winner.randNum.S,
            droppedTokenAddress: winner.droppedTokenAddress.S,
            droppedTokenId: winner.droppedTokenId.S,
            timestamp: winner.timestamp.S,
            txHash: winner.txHash.S,
            winningAddress: winner.winningAddress.S,
            winningTokenId: winner.winningTokenId.S,
        }
    })
}