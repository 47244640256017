import ScaleText from "react-scale-text";
import { Eth } from '../utils/fas';

export const PriceTag = props => {
  var price = (props.price * props.amount).toFixed(4);
  for (var i = 1; price.slice(-1) === "0"; i++) {
    price = Number(price).toFixed(4-i);
  }
  const priceJSX = <span>{price}{" "}<Eth/></span>;

  return (
    <div className="shape">
      <span id="priceTag">
        <ScaleText>
          {priceJSX}
        </ScaleText>
      </span>
    </div>
  )
}
