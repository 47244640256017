import { useEffect, useState } from 'react'
import { getWinners, unmarshal } from '../utils/ddb'
import { CONTRACT_ADDRESS } from '../utils/constants'
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { indigo } from '@mui/material/colors';
import { isMobile } from 'react-device-detect';

const env = process.env.NODE_ENV

const header_color = indigo[900]

const openSeaPrefix = env === 'development' ? "https://testnets.opensea.io/assets/goerli/" : "https://opensea.io/assets/ethereum/"
const etherscanPrefix = env === 'development' ? "https://goerli.etherscan.io/" : "https://etherscan.io/"

const Winners = props => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: header_color,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
    
    const winners = unmarshal(props.winners)
    const sortedWinners = winners.sort((a, b) => {
        return new Date(b.timestamp) - new Date(a.timestamp)
    })

    const shorten = (str) => {
        return <Tooltip title={str} placement="bottom" arrow={true}>
            <p>{str.substring(0, 6) + "..." + str.substring(str.length - 4, str.length)}</p>
        </Tooltip>
    }

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp)
        return date.toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short'})
    }

    const tableWidth = isMobile ? "100vw" : "75vw"
    
    return (
        <div className="Winners">
                <TableContainer component={Paper} style={{maxWidth: tableWidth, margin: "0 auto"}}>
                    <Table size="small" sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Timestamp</StyledTableCell>
                                <StyledTableCell>Seed</StyledTableCell>
                                <StyledTableCell>Random Number</StyledTableCell>
                                <StyledTableCell>Winning Token</StyledTableCell>
                                <StyledTableCell>Dropped Token</StyledTableCell>
                                <StyledTableCell>Winning Address</StyledTableCell>
                                <StyledTableCell>Tx Hash</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedWinners.map((winner, index) => {
                                return (
                                    <StyledTableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell component="th">{formatTimestamp(winner.timestamp)}</StyledTableCell>
                                        <StyledTableCell><span style={styles.hoverNumber}>{shorten(winner.seed)}</span></StyledTableCell>
                                        <StyledTableCell><span style={styles.hoverNumber}>{shorten(winner.randNum)}</span></StyledTableCell>
                                        <StyledTableCell><a href={`${openSeaPrefix}${CONTRACT_ADDRESS}/${winner.winningTokenId}`} target="_blank" rel="noreferrer">{winner.winningTokenId}</a></StyledTableCell>
                                        <StyledTableCell><a href={`${openSeaPrefix}${winner.droppedTokenAddress}/${winner.droppedTokenId}`} target="_blank" rel="noreferrer">{winner.droppedTokenId}</a></StyledTableCell>
                                        <StyledTableCell><a href={`${etherscanPrefix}address/${winner.winningAddress}`} target="_blank" rel="noreferrer">{shorten(winner.winningAddress)}</a></StyledTableCell>
                                        <StyledTableCell><a href={`${etherscanPrefix}tx/${winner.txHash}`} target="_blank" rel="noreferrer">{shorten(winner.txHash)}</a></StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
        </div>
    )
}

export const WinnerList = props => {
    const [winners, setWinners] = useState([])

    useEffect(() => {
        const getData = async () => {
            try {
                const { data } = await getWinners()
                setWinners(data)
            }
            catch (e) {
                console.log("Error getting winners...")
            }
        }
        getData()
        const interval = setInterval(() => {
            getData()
        }, 10000) // checks for new winners every 10 seconds
        return () => clearInterval(interval)
    }, [])

    return (
        <div className="NFTList">
            <div style={styles.centered}>
                <Winners 
                    winners={winners}
                    {...props}
                />
            </div>
        </div>      
    )

}

const styles = {
    centered: {
        margin: "0 auto",
        textAlign: "center",
    },
    hoverNumber: {
        cursor: "pointer",
    }
}
