import toast from 'react-hot-toast';
import { mintNFT } from "../utils/interact.js";

export const MintButton = props => {
  const onMintPressed = async () => {
    if (props.mintActive) {
      const { status } = await mintNFT(String(props.amount));
      props.setStatus(status);
    } else {
      toast("Not minting yet!", {icon: '🙅‍♂️'});
    }
  };
  return (
    <button id="mintButton2" onClick={onMintPressed}>
      MINT
    </button>
  )
}
