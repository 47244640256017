import { isMobile } from 'react-device-detect'


export const SelectionProcess = props => {
    return (
        <div style={isMobile? styles.processMobile : styles.process}>
            <h3>Selection Process:</h3>
            <p>When the <code>drop()</code> function is called, a <code>Seed</code> is generated by concatenating the block difficulty 
            and timestamp of the block that the transaction is mined in.</p>
            <code>
                Seed = keccak256(block.difficulty + block.timestamp)
            </code>
            <p>This <code>Seed</code> is used to select a number between 0 and the <code>TotalSupply</code> of Dropper NFTs.</p>
            <code>SeedTokenId = Seed % TotalSupply</code>
            <p>The address of the owner of the chosen NFT is then concatenated with the block difficulty and timestamp to 
            generate a new <code>RandomNumber</code>.</p> 
            <code>
                RandomNumber = keccak256(block.difficulty + SeedTokenAddress + block.timestamp)
            </code>
            <p>This number is used to select a <code>WinningTokenId</code> from the selected range of Dropper NFTs!</p>
            <code>
                WinningTokenId = RandomNumber % (TotalSupply - (TotalSupply - DropRange)) + StartOffset 
            </code>
        </div>
    )
}

const styles = {
    // simple white card with a shadow to explain the selection process... center it
    process: {
        backgroundColor: "#fff",
        borderRadius: "0.5rem",
        padding: "1rem",
        boxShadow: "0 0 0.5rem 0.5rem #aaa",
        color: "#1A1A1A",
        width: "50%",
        margin: "1.5rem auto",
    },
    // adjusted for mobile
    processMobile: {
        backgroundColor: "#fff",
        borderRadius: "0.5rem",
        padding: "1rem",
        boxShadow: "0 0 0.5rem 0.5rem #aaa",
        color: "#1A1A1A",
        width: "80%",
        margin: "1.5rem auto",
    },
}