import { Twitter } from '../utils/fas';
import Opensea from '../images/os.png';
import {Spacer} from '.';
import Sauce from '../images/sauce.png';


export const RenderTextContent = props => {
  return text;
}

const text = <div id="text-content-container">
  <div id="text-content">
    <h1>What is DeeD DroPPerS?</h1>
    <p>
      Exactly what it sounds like! We drop metaverse deeds.
      Otherdeeds are first on our list.
    </p>
    <h1>Why DeeD DroPPerS?</h1>
    <p>
      Many have been priced out of top-tier NFTS.
      With the surge of free mints, we see there's
      always a thirst for the golden ticket.
      We want to bring a legitimacy to that chase
      for the prize. This is your chance to own
      land in the premier metaverse of web3.
    </p>
    <h1>So how does it work?</h1>
    <p>
      We have 200 NFTs that will be minted.
      A week after the final mint,
      an Otherdeed will be dropped to a random
      holder of our v1 series NFTs.
      Other NFTs/prizes will be dropped to
      random holders as well. Follow on
      Discord and Twitter for more info.
    </p>
    <h1>What happens after the drawing?</h1>
    <p>
      We will run various series of drops similar
      to the first series.
    </p>
    <h1>Should I keep my v1 NFT after the drawing?</h1>
    <p>
      We might have a plan for them later. You might want to hodl ;).
    </p>
    <h1>Who is bringing you DeeD DroPPerS?</h1>
    <p>
      <a
        className="social-link"
        href="https://twitter.com/localcryptogod" target="_blank" rel="noreferrer">
        @localcryptogod
      </a>,{" "}
      <a className="social-link"
        href="https://twitter.com/crypt0potamus" target="_blank" rel="noreferrer">
        @crypt0potamus
      </a>,
      and friends...
    </p>
    <h1>Where?</h1>
    <div className="social-icon">
      <a href="https://twitter.com/deeddroppers/" target="_blank" rel="noreferrer">
        <Twitter />
      </a>
        {" "}
      <a href="https://opensea.io/">
        <span className="icon-container">
          <img src={Opensea} id="os-icon" alt="Opensea Logo"></img>
        </span>
      </a>
    </div>
    <h1>When?</h1>
    <p><strong>Mint:</strong> TBD</p>
    <p><strong>Drop:</strong> Week after mint-out.</p>
    <Spacer size="3"/>
    <h2>Official Sponsor of Sauce on the Block</h2>
    <img src={Sauce} id="sauce-logo" alt="Sauce on the Block Logo"></img>
    <p>We are an official sponsor/partner with Sauce on the Block! We will be using their discord for Drop Night! Also look for their Twitter Spaces for mint parties! Founded by 
      <a
        className="social-link"
        href="https://twitter.com/DerickGvibe" target="_blank" rel="noreferrer">
        @DerickGvibe
      </a>,{" "}
      <a className="social-link"
        href="https://twitter.com/ALLDAYCUHHH" target="_blank" rel="noreferrer">
        @ALLDAYCUHHH
      </a>, </p>
    <div className="social-icon">
      <a href="https://twitter.com/SauceOntheBlock/" target="_blank" rel="noreferrer">
        <Twitter />
      </a>
      </div>
    <h2>Notice of Non-Affiliation and Disclaimer:</h2>
    <p><i>We are not affiliated, associated, authorized, endorsed by,
    or in any way officially connected with Yuga Labs or any of its
    subsidiaries or its affiliates.
    The official Otherdeeds website can be found at{" "}
    <a href="https://www.otherside.xyz" target="_blank" rel="noreferrer">https://www.otherside.xyz</a>.
    The name Otherdeeds as well as related names, marks, emblems and images
    are registered trademarks of their respective owners.</i>
    </p>
  </div>
</div>
