import { useState, useEffect } from "react"
import { nfts } from "../utils/metadata"
import { dropNft, getLogs } from "../utils/interact"
import { NFTInput, Spacer } from "../components"
import { addNewDBItem } from "../utils/ddb"
import toast, { Toaster } from "react-hot-toast"

const env = process.env.NODE_ENV
const chainName = env === "development" ? "Goerli Testnet" : "Ethereum Mainnet"

const shorten = (str) => {
    return str ? str.slice(0, 6) + "..." + str.slice(-4) : ""
}

const VIDEO_LENGTH = 30000 // Length of the loading video in milliseconds

const NFTCard = props => {
    if (!props.nft) return null
    const { name, img, description } = nfts[props.nft]
    return (
        <div className="nft-card" style={styles.nftCardContainer}>
            <div className="nft-card-image" style={styles.nftCardImageContainer}>
                <img src={img} alt={name} style={styles.nftCardImage} />
            </div>
            <div className="nft-card-description" style={styles.nftCardDescription}>{description}</div>
        </div>
    )
}

export const Dropper = () => {

    const [startToken, setStartToken] = useState(null)
    const [endToken, setEndToken] = useState(null)
    const [selectedNft, setSelectedNft] = useState(null)
    const [txHash, setTxHash] = useState(null)
    const [isWinner, setIsWinner] = useState(false)
    const [logs, setLogs] = useState({
        seed: "",
        randNum: "",
        droppedTokenAddress: "",
        winningTokenId: "",
        droppedTokenId: "",
        winningAddress: "",
        txHash: ""
    })
    const [loading, setLoading] = useState(false)

    const messages = [
        'Enter the range of tokens that are eligible and select an NFT to drop.',
        'Ready to drop!',
        'Dropping!'
    ]

    useEffect(() => {
        const getData = async () => {
            let logs
            while (!logs) {
                let timestamp = new Date().getTime()
                setLoading(true)
                try {
                    logs = await getLogs(txHash)
                    let timeDiff = new Date().getTime() - timestamp
                    if (timeDiff < VIDEO_LENGTH) {
                        console.log("Waiting for video to finish...")
                        await new Promise(resolve => setTimeout(resolve, VIDEO_LENGTH - timeDiff))
                    }
                    setLogs(logs)
                    console.log("Winner revealed!")
                    setLoading(false)
                } catch (e) {
                    console.log("Waiting for transaction to be confirmed...")
                    await new Promise(resolve => setTimeout(resolve, 5000))
                }
            }
        }
        if (txHash) {
            getData()
        }
    }, [txHash])

    useEffect(() => {
        if (logs.seed) {
            setIsWinner(true)
            addNewDBItem(logs)
        } else {
            setIsWinner(false)
        }
    }, [logs])

    const handleDropNft = async () => {
        if (!startToken || !endToken || selectedNft === -1) {
            toast.error('Please enter a valid range and select an NFT.')
            return
        } else if (startToken > endToken) {
            toast.error('Start token must be less than or equal to end token.')
            return
        } else if (startToken < 0 || endToken < 0) {
            toast.error('Start token and end token must be greater than or equal to 0.')
            return
        } else if (!window.ethereum) {
            toast.error('Please install MetaMask.')
            return
        } else if (!window.ethereum.selectedAddress) {
            toast.error('Please connect to MetaMask.')
            return
        } else if (window.ethereum.chainId !== (env === 'development' ? '0x5' : '0x1')) {
            toast.error(`Please connect to the ${chainName} network.`)
            return
        }
        const { success, txHash } = await dropNft(startToken, endToken, nfts[selectedNft].address, nfts[selectedNft].tokenId)
        if (!success) {
            toast.error('Transaction failed.')
            return
        }
        setTxHash(await txHash)
    }

    const handleReset = () => {
        setStartToken('')
        setEndToken('')
        setSelectedNft(-1)
        setTxHash(null)
        setLogs({
            seed: "",
            randNum: "",
            winningTokenId: "",
            droppedTokenAddress: "",
            droppedTokenId: "",
            winningAddress: ""
        })
    }

    // mp4 video when loading
    const Loading = () => {
        return (
            <div style={styles.backShadow}>
                <div style={styles.videoContainer}>
                    <div style={styles.videoMask}>
                        <video autoPlay loop style={styles.video}>
                            <source src="https://deeddroppers.s3.amazonaws.com/loading.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        )
    }

    const WinnerArea = () => {
        return (
            <div style={styles.winnerContainer}>
                <h1 style={{ fontFamily: "Slackey" }}>Dropped!</h1>
                <NFTCard nft={selectedNft} styles={styles} />
                <p><b>{logs.winningTokenId ? 'Winning Token ID:' : ''}</b> <a href={`https://testnets.opensea.io/assets/goerli/0x2bd6c5d3dbf793844ad1e654327d7383cd2cb5ff/${logs.winningTokenId}`} target="_blank" rel="noreferrer"><br></br><span style={{ fontSize: "100px", fontWeight: "bold" }}>{logs.winningTokenId}</span></a></p>
                <p><b>{logs.winningAddress ? 'Winning Address:' : ''}</b> <a href={`https://goerli.etherscan.io/address/${logs.winningAddress}`} target="_blank" rel="noreferrer"><br></br>{shorten(logs.winningAddress)}</a></p>
                <p><b>{txHash ? 'Tx Hash:' : ''}</b> <a href={`https://goerli.etherscan.io/tx/${txHash}`} target="_blank" rel="noreferrer"><br></br>{shorten(txHash)}</a></p>
                <button style={styles.button} onClick={() => handleReset()}>Close</button>
            </div>
        )
    }

    const DropButton = () => {
        return (
            <div style={styles.centered}>
                <button style={styles.button} onClick={() => handleDropNft()}>Drop NFT</button>
            </div>
        )
    }

    const ResetButton = () => {
        return (
            <div style={styles.centered}>
                <button style={styles.button} onClick={() => handleReset()}>Reset</button>
            </div>
        )
    }

    const Status = props => {
        return (
            <div>
                <p style={{ textAlign: "center", color: "black" }}>{!startToken || !endToken || !selectedNft ? messages[0] : !txHash ? messages[1] : messages[2]}</p>
            </div>
        )
    }

    return (
        <div className="Dropper">
            <Toaster />
            <h1 style={styles.title}>Da Dropper</h1>
            <NFTInput
                startToken={startToken}
                endToken={endToken}
                selectedNft={selectedNft}
                setStartToken={setStartToken}
                setEndToken={setEndToken}
                setSelectedNft={setSelectedNft}
                styles={styles}
                nfts={nfts}
            />
            {selectedNft !== -1 && <NFTCard nft={selectedNft} styles={styles} />}
            <DropButton />
            <Spacer />
            <ResetButton />
            <Status />
            {loading && <Loading />}
            {isWinner && <WinnerArea />}
        </div>
    )
}

export default Dropper

const styles = {
    backShadow: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "100",
    },
    input: {
        fontSize: "1.5rem",
        padding: "0.5rem",
        borderRadius: "0.5rem",
        border: "none",
        backgroundColor: "#fffcef",
        color: "#1A1A1A",
        marginBottom: "1rem",
        maxWidth: "20rem",
    },
    centered: {
        margin: "0 auto",
        textAlign: "center",
    },
    // fixed, centered card that will contain loading video
    videoContainer: {
        backgroundColor: "#1A1A1A",
        padding: "20px",
        borderRadius: "4rem",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    videoMask: {
        borderRadius: "60px",
        overflow: "hidden",
    },
    winnerContainer: {
        backgroundColor: "#1A1A1A",
        padding: "1.5rem",
        borderRadius: "0.5rem",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        minWidth: "40vw",
        minHeight: "60vh",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#1A1A1A",
        color: "#fffcef",
        textAlign: "center",
    },
    title: {
        fontSize: "5rem",
        fontWeight: "bold",
        marginBottom: "1rem",
        textAlign: "center",
        fontFamily: "Slackey"
    },
    subtitle: {
        fontSize: "1.5rem",
        marginBottom: "1rem",
        textAlign: "center",
    },
    button: {
        fontSize: "1.5rem",
        borderRadius: "0.5rem",
        border: "none",
        backgroundColor: "#fffcef",
        color: "#1A1A1A",
        cursor: "pointer",
    },
    nftCardContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black",
        padding: "1rem",
        borderRadius: "0.5rem",
        margin: "auto",
        marginBottom: "1rem",
        width: "200px",
        height: "200px",
        border: "1px solid #fffcef",
    },
    nftCardImageContainer: {
        width: "100px",
        height: "100px",
        borderRadius: "0.5rem",
    },
    nftCardImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "0.5rem",
    },
    nftCardDescription: {
        fontSize: "1rem",
        color: "#fffcef",
        textAlign: "center",
        marginTop: "0.5rem",
        fontWeight: "bold",
    },
}